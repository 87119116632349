import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../images/fullWidthLogoDark.png';
import img2 from '../images/Polygon MATIC.png';

const PowerBy = ({width}) => {
    return (
        <>
            <div className="my-4 py-4 h-full" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-yellow-300 uppercase font-bold mt-24">Powered By</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4 flex justify-center items-center" data-aos="fade-down" data-aos-delay="600">
                    <img alt="card img" className="ml-24 duration-1000 w-1/5 " src={img1} />
                    <img alt="card img" className="ml-24 duration-1000 w-1/5 " src={img2} />
                </div>
            </div>
        </>
    )
}

export default PowerBy;
