import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Typist from "react-typist";
import Logo from "../images/coach.png";
import coinLogo from "../images/logo-coin.png";
import blastLogo from "../images/logo-blast.png";
import Presale from "./Presale";

const Hero = ({width}) => {
    return (
        <>

            <div className="hero flex justify-center items-center flex-col ">

                <div className={`m-auto overflow-hidden mx-4 mt-8 lg:mt-4 md:p-0 h-screen w-full flex items-start justify-center`}>
                    <div className="flex flex-col lg:flex-row justify-center text-center w-full items-start">
                        <div className="w-2/5 flex flex-col justify-center items-center pt-52" >

                                <h1 className="md:text-8xl text-6xl font-bold text-yellow-500">
                                    <Typist>
                                        PolyCoach
                                    </Typist>
                                </h1>
                                
                                <div className="text-3xl font-semibold tracking-tight mt-10 text-gray-900">
                                    <Typist
                                      className="TypistExample-message"
                                      cursor={{ hideWhenDone: true }}
                                    >
                                        <Typist.Delay ms={750} />
                                            A DApp built on Polygon that helps you train good habits through Web3. Train good habits and earn $COACH.
                                        <Typist.Delay ms={500} />
                                    </Typist>
                                </div>

                        </div>
                        {/* <div className="flex w-2/5 lg:w-2/5 h-1/2 justify-center" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t float-right duration-1000 h-1/3" src={Logo} />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;
