import React from 'react';
import img from '../images/trainer.png';
import img2 from '../images/online-exam.png';
import img3 from '../images/investor.png'

const Tokenomics = ({width}) => {

    return (
      <>
          <ul className="background">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
          </ul>
        <section id="tokenomics" />
        <div id="services" className="py-12 flex justify-center items-center h-100%" >
            <div className={width}>
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-4xl text-yellow-300 uppercase font-bold">PolyCoach</h2>
                        <div className='flex justify-center'>
                            <div className='w-36 border-b-4 border-blue-900'></div>
                        </div>
                        {/*<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-100">We are deeply committed to the growth and success of our clients.</h2>*/}
                    </div>
                    <div className="my-4 py-4 w-full flex justify-center">
                        <p className="my-2 text-center text-xl text-zinc-100 font-bold w-4/5">
                            PolyCoach is a dApp built on Polygon that helps you use the 21-days rule to train good habits through Web3 style. 
                            You can create an exclusive Coach on the Polygon mainnet to train a good habit by depositing a certain amount of tokens into the Coach contract and continuously withdrawing these tokens during 21 consecutive check-ins and earn $COACH token.     
                        </p>
                        {/*<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-100">We are deeply committed to the growth and success of our clients.</h2>*/}
                    </div>
                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-3">

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-between h-full p-5">
                                    <h2 className="font-semibold my-4 text-2xl text-center text-violet-300">Check-Up To Earn</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-1/2" style={{height: 300}} src={img} />

                                    <p className="text-md font-medium">
                                        When you successfully create an coach on-chain, it means you deposited some tokens. For the next 21 days, you need to check in every day and cast to show your training results. You can get back part of the tokens every time you check in. After completing the entire training you will receive $Coach rewards.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-between h-full p-5">
                                    <h2 className="font-semibold my-4 text-2xl text-center text-violet-300">Supervise To Earn</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-1/2" src={img2} />
                                    <p className="text-md font-medium">
                                        If you are interested in someone's training process, you can choose to become his supervisor. You don't need to pay anything, you only need to pay attention to the content of his check-in cast. We hope that with this kind of social pressure, trainers can sincerely and proactively complete the training. After the trainer completes 21 check-ins, you will receive $COACH rewards.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-zinc-800 transition-all ease-in-out duration-400  overflow-hidden text-gray-300 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-between h-full p-5">
                                    <h2 className="font-semibold my-4 text-2xl text-center text-violet-300">Donate To Earn</h2>
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-1/2" src={img3} />
                                    <p className="text-md font-medium">
                                        If you recognize someone's training process very much, you can choose to donate to him to increase his enthusiasm. After the trainer completes 21 check-ins, you will receive $COACH rewards.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
      </>
    )
}

export default Tokenomics;
