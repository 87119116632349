import React from 'react';
import slerfHeat from '../images/coach.png';
import { Tweet } from 'react-tweet';

const About = ({width}) => {
  return (
    <div className="h-full" id="about">

      <div className="m-auto w-2/3 p-2 md:p-12 flex justify-center items-center " data-aos="zoom-in-down">
        <div data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-4xl text-yellow-300 uppercase font-bold ">The 21-Day Habit-Forming Theory</h2>
            <div className='flex justify-center'>
                            <div className='w-96 border-b-4 border-blue-900'></div>
                        </div>
            {/*<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-100">We are deeply committed to the growth and success of our clients.</h2>*/}
          </div>
          <div className="my-4 py-4 w-full flex justify-between">
            <p className="my-2 text-xl text-zinc-100 font-bold w-5/6 text-left">
            The 21-day habit formation theory originates from the observations of psychologist Maxwell Maltz, who introduced this concept in his 1960 book Psycho-Cybernetics. He initially noticed that patients, after undergoing significant changes such as amputations or cosmetic surgery, typically needed about 21 days to adjust to their new circumstances. From this, he hypothesized that it takes around 21 days of repeating a new behavior for a person to start internalizing it and forming a habit.
            <br />
            <br />
            However, while this theory has become widely popular, modern research suggests that the time required to form a habit varies from person to person and depends on factors such as the complexity of the behavior, individual willpower, and environmental influences. According to a 2010 study by University College London, on average, it takes a person 66 days to fully transform a new behavior into a habit. Nevertheless, the 21-day timeframe remains a simple guideline, encouraging people to maintain persistence and patience when trying to change their lifestyle or establish new habits.            
            </p>
            {/*<h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-zinc-100">We are deeply committed to the growth and success of our clients.</h2>*/}
            <img alt="card img" className="ml-24 duration-1000 h-1/5 " style={{height: 400}} src={slerfHeat} />

          </div>
          
          {/* <div className="flex justify-between items-center m-5">
            <img alt="card img" className="rounded-t float-right duration-1000 h-1/5" style={{height: 400}} src={slerfHeat} />
            <div className="w-1/2">
              <div data-theme="dark">
                <Tweet id="1769628588809031864" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default About;
