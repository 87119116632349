import React from 'react';
import {Link} from 'react-router-dom';



const Cta = () => {
    return (
        <div className="w-full flex items-center justify-center text-white cta bg-black opacity-80">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
                <div className="w-full flex flex-col lg:flex-row lg:justify-center lg:items-center" >
                    <div className="">
                        <p className='text-2xl md:text-4xl font-bold'>Join and become a PolyCoach early birds to get airdrops</p>
                    </div>

                    <div className="w-full lg:w-72 lg:mx-12">
                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-center">
                                <li className="ml-4">
                                    <a href="https://x.com/PolyCoachOG" className="flex justify-center items-center text-zinc-400 hover:text-gray-500 bg-zinc-400 hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="twitter">
                                        <svg className="w-20 h-20 fill-current" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
                                            <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z"/>
                                            <path fill="#000" fill-rule="nonzero" d="M318.64 157.549h33.401l-72.973 83.407 85.85 113.495h-67.222l-52.647-68.836-60.242 68.836h-33.423l78.052-89.212-82.354-107.69h68.924l47.59 62.917 55.044-62.917zm-11.724 176.908h18.51L205.95 176.493h-19.86l120.826 157.964z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="ml-8">
                                    <a href="https://github.com/PolyCoach" className="flex justify-center items-center text-zinc-400 hover:text-gray-500 bg-black hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="telegram">
                                        <svg xmlns="http://www.w3.org/2000/svg"className="w-20 h-20 fill-current" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 640 640"><path d="M319.988 7.973C143.293 7.973 0 151.242 0 327.96c0 141.392 91.678 261.298 218.826 303.63 16.004 2.964 21.886-6.957 21.886-15.414 0-7.63-.319-32.835-.449-59.552-89.032 19.359-107.8-37.772-107.8-37.772-14.552-36.993-35.529-46.831-35.529-46.831-29.032-19.879 2.209-19.442 2.209-19.442 32.126 2.245 49.04 32.954 49.04 32.954 28.56 48.922 74.883 34.76 93.131 26.598 2.882-20.681 11.15-34.807 20.315-42.803-71.08-8.067-145.797-35.516-145.797-158.14 0-34.926 12.52-63.485 32.965-85.88-3.33-8.078-14.291-40.606 3.083-84.674 0 0 26.87-8.61 88.029 32.8 25.512-7.075 52.878-10.642 80.056-10.76 27.2.118 54.614 3.673 80.162 10.76 61.076-41.386 87.922-32.8 87.922-32.8 17.398 44.08 6.485 76.631 3.154 84.675 20.516 22.394 32.93 50.953 32.93 85.879 0 122.907-74.883 149.93-146.117 157.856 11.481 9.921 21.733 29.398 21.733 59.233 0 42.792-.366 77.28-.366 87.804 0 8.516 5.764 18.473 21.992 15.354 127.076-42.354 218.637-162.274 218.637-303.582 0-176.695-143.269-319.988-320-319.988l-.023.107z"/></svg>
                                    </a>
                                </li>
                                <li className="ml-8">
                                    <a href="https://warpcast.com/polycoach" className="flex justify-center items-center text-zinc-900 hover:text-gray-500 bg-zinc-400  hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="telegram">
                                        <svg xmlns="http://www.w3.org/2000/svg"className="w-20 h-20 fill-current"  width="1000" height="1000" viewBox="0 0 1000 1000" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" >
                                            <path d="M257.778 155.556H742.222V844.445H671.111V528.889H670.414C662.554 441.677 589.258 373.333 500 373.333C410.742 373.333 337.446 441.677 329.586 528.889H328.889V844.445H257.778V155.556Z" />
                                            <path d="M128.889 253.333L157.778 351.111H182.222V746.667C169.949 746.667 160 756.616 160 768.889V795.556H155.556C143.283 795.556 133.333 805.505 133.333 817.778V844.445H382.222V817.778C382.222 805.505 372.273 795.556 360 795.556H355.556V768.889C355.556 756.616 345.606 746.667 333.333 746.667H306.667V253.333H128.889Z" />
                                            <path d="M675.556 746.667C663.282 746.667 653.333 756.616 653.333 768.889V795.556H648.889C636.616 795.556 626.667 805.505 626.667 817.778V844.445H875.556V817.778C875.556 805.505 865.606 795.556 853.333 795.556H848.889V768.889C848.889 756.616 838.94 746.667 826.667 746.667V351.111H851.111L880 253.333H702.222V746.667H675.556Z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Cta;
