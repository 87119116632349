import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {ConnectButton} from "@rainbow-me/rainbowkit";
import { Button } from '@mui/material';

const NavLinks = () => {
    return (
        <div className="flex items-center">
            <a className="px-4 font-extrabold text-yellow-300 hover:text-yellow-100 text-2xl" href="https://github.com/PolyCoach">
                Github
            </a>
            <a className="px-4 font-extrabold text-yellow-300 hover:text-yellow-100 text-2xl" href="https://docs.polycoach.xyz">
                Docs
            </a>
            <a className="px-4 font-extrabold text-yellow-300 hover:text-yellow-100 text-2xl" href="https://x.com/PolyCoachOG">
                X
            </a>
            <a className="px-4 font-extrabold text-yellow-300 hover:text-yellow-100 text-2xl" href="https://warpcast.com/polycoach">
                Farcaster
            </a>
            <a className="px-8 ml-10 font-extrabold text-2xl focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" href="https://warpcast.com/polycoach/0xc687563b">
                DApp
            </a>
        </div>
    )
}

export default NavLinks;
