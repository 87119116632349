import React, {useState} from 'react';
import "./presale.css";
import coinLogo from "../images/logo-coin.png";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import {useAddRecentTransaction} from "@rainbow-me/rainbowkit";
import useContractCall from "../hooks/useContractCall";
import {slerfHatAddress, slerfHatContract, wETHContract} from "../config";
import {convertToEtherAccuracy, fixedNumber} from "../utils/common";
import TextField from "@mui/material/TextField";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fbff31',
      second: '#fdfa00',
    },
  },

});

const Presale = ({ethBalance, wETHBalance, wETHApprove, alreadyPreSaleAmount, totalAlreadyPreSaleAmount}) => {
  const [selectedAsset, setSelectedAsset] = useState('ETH');
  const [preSaleAmount, setPreSaleAmount] = useState(0);

  let approve = useContractCall(wETHContract, 'approve', [slerfHatContract.address, convertToEtherAccuracy(preSaleAmount)], 0, "Approve WETH.");
  let presaleWithETH = useContractCall(slerfHatContract, 'preSaleWithETH', [], convertToEtherAccuracy(preSaleAmount), `Presale ${preSaleAmount} ETH.`);
  let presaleWithWETH = useContractCall(slerfHatContract, 'preSaleWithWETH', [convertToEtherAccuracy(preSaleAmount)], 0, `Presale ${preSaleAmount} WETH.`)

  return (
    <div className="w-full" data-aos="zoom-in-down">
      <h2 className="mb-5 md:text-3xl text-3xl font-bold text-yellow-300">
        Join the Presale
      </h2>
      <div className="flex justify-between items-center ">
        <div className="bg-clip-content p-6 border-4 border-violet-300 border-dashed rounded-lg w-1/2">
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/5 text-white text-base">
              Presale Price:
            </p>
            <p className="font-medium w-4/5 text-white text-base pl-5 text-right">
              0.000002 ETH
            </p>
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/5 text-white text-base">
              Presale Duration:
            </p>
            <p className="font-medium w-4/5 text-white text-base pl-5 text-right">
              2024-03-21 08:00:00 UTC - 2024-03-26 08:00:00 UTC
            </p>
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/5 text-white text-base">
              Presale Hardcap:
            </p>
            <p className="font-medium w-4/5 text-white text-base pl-5 text-right">
              1,000ETH
            </p>
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/5 text-white text-base">
              Current Presale Amount:
            </p>
            <p className="font-medium w-4/5 text-white text-base pl-5 text-right">
              {totalAlreadyPreSaleAmount} ETH
            </p>
          </div>
          <div className="flex justify-between items-center  m-5">
            <p className="font-medium w-1/5 text-white text-base">
              Presale Progress:
            </p>
            <div className="meter w-3/5">
              <span style={{width: `${totalAlreadyPreSaleAmount * 100 / 1000}%`}} />
            </div>
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/5 text-white text-base">
              Presale Claim:
            </p>
            <p className="font-medium w-4/5 text-white text-base pl-5 text-right">
              2024-03-26 09:00:00 UTC
            </p>
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/5 text-white text-base">
              Fuck-up Ratio:
            </p>
            <p className="font-medium w-4/5 text-white text-base pl-5 text-right">
              50%-130% (Each Pre-Sale Participant's $HAT-Receive Randomly Multiply by 50% ~ 130%)
            </p>
          </div>
        </div>
        <div className="flex justify-center w-2/5 flex-col">
          <div className="flex justify-center items-center m-5">
            <img src={coinLogo} style={{ marginTop: 0, height: 150, padding: '0px 10px' }}  alt="SlerfHat" />
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/2 text-white text-base">
              Your Presale Amount:
            </p>
            <p className="font-medium w-1/2 text-white text-base pl-5 text-right">
              {fixedNumber(alreadyPreSaleAmount * 500000, 2)} HAT ({alreadyPreSaleAmount} ETH)
            </p>
          </div>
          <div className="flex justify-between items-center m-5">
            <p className="text-md font-medium w-1/2 text-white text-base">
              Your Presale Quota:
            </p>
            <p className="font-medium w-1/2 text-white text-base pl-5 text-right">
              0.0001 ETH ~ 10 ETH
            </p>
          </div>
          <ThemeProvider theme={darkTheme}>
          <div className="flex justify-between items-center m-5">

            <FormControl>
              <InputLabel>Asset</InputLabel>
                <Select
                  value={selectedAsset}
                  label="Asset"
                  onChange={(event) => {
                    setSelectedAsset(event.target.value);
                  }}
                  sx={{border: 'white'}}
                >
                  <MenuItem
                    key='ETH'
                    sx={{ width: "100%" }}
                    value="ETH"
                  >
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img src={coinLogo} style={{ marginTop: 0, height: 25, padding: '0px 10px' }} alt="ETH" />
                      ETH
                    </Box>
                  </MenuItem>
                  <MenuItem
                    key='WETH'
                    sx={{ width: "100%" }}
                    value="WETH"
                  >
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img src={coinLogo} style={{ marginTop: 0, height: 25, padding: '0px 10px' }} alt='WETH' />
                      WETH
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            <Box sx={{ m: "0px 5px", width: "80%" }}>
              <TextField
                fullWidth
                label={`Balance: ${selectedAsset === 'ETH' ? ethBalance : wETHBalance} ${selectedAsset}`}
                id="outlined-start-adornment"
                sx={{ m: 1, width: '100%' }}
                type='number'
                inputProps={{
                  maxLength: 13,
                  step: "0.0001"
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <Button onClick={() => {
                      setPreSaleAmount(selectedAsset === 'ETH' ? (ethBalance > 0.0001? ethBalance - 0.0001 : 0) : wETHBalance);
                    }}>
                      Max
                    </Button>
                  </InputAdornment>,
                }}
                value={preSaleAmount}
                onChange={(event) => {
                  setPreSaleAmount(Number(event.target.value ? event.target.value : 0));
                }}
              />
            </Box>
          </div>
          <div className="flex justify-between items-center m-5">
            {
              (selectedAsset === 'WETH' && wETHApprove < preSaleAmount && preSaleAmount !== 0) ? <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={approve}
              >Approve</Button> : <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={selectedAsset === 'ETH' ? presaleWithETH : presaleWithWETH}
                disabled={!(preSaleAmount <= 10 && preSaleAmount >= 0.0001)}
              > { preSaleAmount <= 10 && preSaleAmount >= 0.0001 ? 'Presale' : 'Enter a number ranging from 0.0001 to 10 ETH' }</Button>
            }
          </div>
          </ThemeProvider>
        </div>
      </div>



      {/*<div class="meter orange nostripes">*/}
      {/*  <span style={{width: '33%'}}></span>*/}
      {/*</div>*/}

      {/*<div class="meter red">*/}
      {/*  <span style={{width: '80%'}}></span>*/}
      {/*</div>*/}
      {/*<div class="meter animate">*/}
      {/*  <span style={{width: '50%'}}><span></span></span>*/}
      {/*</div>*/}
    </div>
  )
}

export default Presale;
