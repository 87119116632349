import React from 'react';
import Cta from '../components/Cta';
import Hero from '../components/Hero';
import Tokenomics from '../components/Tokenomics';
import NavBar from "../components/Navbar/NavBar";
import About from "../components/About";
import PowerBy from "../components/PowerBy";

const Home = () => {
  const width = 'w-4/5'
  return (
    <div >
      <NavBar />
      <Hero width={width} />
      <About />
      <Tokenomics width={width}/>
      <PowerBy width={width}/>
      <Cta/>
    </div>
  )
}

export default Home;

